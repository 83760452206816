import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/takeUntil';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/throttleTime';
import 'rxjs/add/operator/merge';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/from';
import 'rxjs/add/observable/timer';
import variables from './variables';
import { facetPreferenceCall } from './ducks';
import { mergeMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { searchUrlReq }  from './searchClientTypes';

/**
 * Make search call to backend on the basis of search client type.
 * @param {} queryPassed
 */
const facetPreferenceEpic = (action$) =>
  action$.pipe(
    ofType(facetPreferenceCall.start().type),
    mergeMap(async ({ queryPassed }) => {
      variables.searchCallVariables.searchString = queryPassed.searchString;
      var paramsUrlReq = searchUrlReq(variables.searchClientType, queryPassed);
      try {
        const response = await fetch(paramsUrlReq.url, paramsUrlReq.req);
        if (!response.ok) {
          throw Error(response.statusText);
        }
        const resp = response;
        const results = await resp.json();  
        return facetPreferenceCall.success(results);
      }
      catch (err) {
        return facetPreferenceCall.fail(err);
      }
    })
  );

export default facetPreferenceEpic;
