import React from 'react';
import variables from '../../../redux/variables';
import AutoLearningSuggestion from 'components/feature-components/auto-suggest-auto-learning/index.jsx';
import { tabIndexes } from '../../../constants/a11y';

const Suggest = (props) => {
  try {
    let currentSearchString = props.currentSearchString;
    let autocompleteResult = props.autoCompleteResult;
    let resultOpenNewTab = props.resultOpenNewTab;

    return (
      <div className="su__autoSuggestion su__autocomplete-suggestion su__w-100 su__bg-white su__sm-shadow su__position-absolute su__zindex-2">
        <div id="my_div" className="su__suggestions-box su__minscroller">
          <div className="auto-suggestion">
            {React.Children.map(props.children,
              child => {
                if (!child) return false
                else if (child.props.position == "aboveAllResult") {
                  return React.cloneElement(child, { autocompleteResult, ...props }, null);
                }
              })}
            {autocompleteResult.result && variables.autocompleteCallVariables.searchString && autocompleteResult.result.hits.length != 0 &&
              React.Children.toArray(autocompleteResult.result.hits.map((item, index) => (
                <div data-key={index} tabIndex={tabIndexes.tabIndex_minus_1} className={`su__suggestions-list su__bg-gray-hover su__d-flex su__font-14 su__text-black ${currentSearchString === (item.highlight.TitleToDisplayString[0] || item.href) ? 'su__highlight_result' : ''}`}>
                  {React.Children.map(props.children,
                    child => {
                      if (!child) return false
                      else if (child.props.position == "icon") {
                        return React.cloneElement(child, { item }, null);
                      }
                    })
                  }
                  <div>
                    {React.Children.map(props.children,
                      child => {
                        if (!child) return false
                        else if (child.props.position == "result") {
                          return React.cloneElement(child, { item, index, resultOpenNewTab }, null);
                        }
                      })}
                  </div>
                </div>
              )))}
            {React.Children.map(props.children,
              child => {
                if (!child) return false
                else if (child.props.position == "belowAllResult") {
                  return React.cloneElement(child, { autocompleteResult }, null);
                }
              })}
          </div>
          {autocompleteResult && autocompleteResult.smartAggregations && autocompleteResult.smartAggregations.length &&
            <div className={`su__product-sugt-row su__minscroller su__product-length-${autocompleteResult && autocompleteResult.smartAggregations && autocompleteResult.smartAggregations.length}`} style={{ position: (autocompleteResult.result && autocompleteResult.result.hits.length) ? "absolute" : "inherit"}}>
              <AutoLearningSuggestion autocompleteResult={autocompleteResult}/> 
            </div>
          }

        </div>
      </div>
    );
  } catch (e) {
    console.log('Error in auto-suggest component', e);
    return (<div></div>);
  }
};



export default Suggest;