export default {
  SearchCrossIcon : '#757575',
  SearchIcon:'#757575',
  AdvanceSearchIconFill:'#919bb0',
  AdvanceSearchEnabledIconFill:'#ffffff',
  AdvanceSearchCrossIcon:'#757575',
  AutoCompleteClearIcon:'#757575',
  AutoCompleteSearchIcon:'#333',
  AutoTunesvgFill:'#8b8b8b',
  boostedSvgFill:'none',
  SaveBookmarksIcon:'#919bb0',
  SaveBookmarksCrossIcon:'#fff',
  ListBookmarksEmptyIcon:'#919bb0',
  ListBookMarksNotEmptyIcon:'#1870D4',
  ListBookmarksInModalIcon:'#fff',
  ListBookmarksCrossIcon:'#fff',
  ToggleViewGridIcon:'#919bb0',
  SettingsGearIcon:'#fff',
  SettingseditLayoutIcon:'#ffffff',
  SettingsSearchTipIcon:'#FF9800',
  FilterListFacetSearchIcon:'#59BEFE',
  FilterListFacetSearchCloseIcon:'#59BEFE',
  FacetPreferenceCrossIcon:'#333' ,
  FacetPreferenceShow_HideEye:'#a4a3a6',
  FacetPreferenceSaveBookmark:'#0082cacc',
  mobileFacetsCloseIcon:'#fff',
  FeaturedSnippetThumbsup_down:'#3E384A',
  FeaturedSnippetSvgFill:'#000000',
  FeatureSnippetSvgPlayGrey:'#CFCFCF',
  ScrollToTopIcon:'#fff',
  KnowledgeGraphThumbsup_down:'#fff',
  SearchResultFeedbackCloseIcon:'#919bb0',
  SearchResultFeedbackViewMore:'#919bb0',
  PreviewIconFill:'#919bb0',
  SolvedsvgFill:'#5bb543',
  UnderConstructionsvgFill:'#008fd3',
  StickyFacetsSvgFill:'#919bb0',
  autolearn_svg_fill :"#b2b5b9",
  search_tip_svg_fill:"#919bb0",
  settings_svg_fill:"#919bb0"

 
};
