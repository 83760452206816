import variables from '../redux/variables';

const searchResponseFormatter = (searchResult) => {
    /** Theme Setting  */ 
    var a = JSON.parse(localStorage.getItem('theme' + variables.searchCallVariables.uid));
    if (a && a.hideObject) {
        variables.toggleDisplayKeys = [...a.hideObject];
    }

    variables.searchCallVariables.showMoreSummary = searchResult.searchClientSettings?.showMore;
    variables.searchCallVariables.minSummaryLength = searchResult.searchClientSettings?.minSummaryLength;
    variables.searchCallVariables.showContentTag = searchResult.searchClientSettings?.contentTag;
    variables.autocompleteCallVariables.resultsPerPage = Number(searchResult.searchClientSettings?.autoComplete);

    return {
        viewedResult: searchResult.searchClientSettings?.ViewedResults == 1 ? true : false,
        metaStatus: (searchResult.metaGraph && Object.keys(searchResult.metaGraph).length > 0) ? true : false,
        relatedTilesStatus: (searchResult.relatedTiles && searchResult.relatedTiles.length > 0) ? true : false,
        featuredSnippetResult: (searchResult.featuredSnippetResult && Object.keys(searchResult.featuredSnippetResult).length > 0) ? true : false,
        didYouMean: (searchResult.suggest && searchResult.suggest.simple_phrase[0]?.options && searchResult.suggest.simple_phrase[0].options.length) ? true : false,
        loadMoreResults: searchResult.searchClientSettings?.moreResultButton == 1 ? true : false,
        navigatePagination: searchResult.searchClientSettings?.pageNoButton == 1 ? true : false,
        scrollPagination: searchResult.searchClientSettings?.scrollPagination == 1 ? true : false,
        advertisements: searchResult.searchClientSettings?.advertisements == 1 ? true : false,
        recommendation: searchResult.searchClientSettings?.recommendations == 1 ? true : false,
        similarSearches: searchResult.searchClientSettings?.similarSearch == 1 ? true : false,
        languageManager: searchResult.searchClientSettings?.languageManager == 1 ? true : false,
        allContentHideFacet: searchResult.searchClientSettings?.hideAllContentSources == 1 ? true : false,
        noResult : searchResult.result && searchResult.result.hits.length == 0 ? true : false,
        hiddenFacet: searchResult.searchClientSettings?.hiddenFacet && searchResult.searchClientSettings?.hiddenFacet.length >0? true:false,
        searchPageFeedback: searchResult.searchClientSettings?.userFeedbackEnabled && searchResult.searchClientSettings?.userFeedbackEnabled.searchExp,
        searchResultFeedback: searchResult.searchClientSettings?.userFeedbackEnabled && searchResult.searchClientSettings?.userFeedbackEnabled.conversionExp,
    }
}

/**
 * htmlSanitizer - sanitizes dangerously set HTML
 * @param {string} _dangerousHTML - dangerous HTML
 * @returns {string} sanitized HTML
 */
export const htmlSanitizer = (_dangerousHTML) => _dangerousHTML.replace(/[`~!@$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, (match) => '&#'+match.charCodeAt()+';').replace(/(?:\r\n|\r|\n|\t)/g, ' ') || '';


export default searchResponseFormatter;