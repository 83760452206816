import React, { useState } from 'react';
import variables from '../../../redux/variables';
import setCookies from "../../../setCookie/setCookie";

const AutoLearningSuggestion = (props) => {
  let autocompleteResult = props.autocompleteResult;
  const [update, setUpdate] = useState(false);


  if(variables.searchCallVariables.smartFacets){
    variables.searchCallVariables.clonedAggregations = JSON.parse(JSON.stringify(variables.searchCallVariables.aggregations));
  }

  variables.autocompleteCallVariables.smartFacetsClicked = true;

  const smartAutocompleteSelection = (smart, filter) => {

    let clickedOrder = autocompleteResult.smartAggregations.findIndex(
      (f) => f.order === smart.order
    );

    let index = -1;
    variables.searchCallVariables.aggregations.some(function (facet, i) {
      if (facet.type == autocompleteResult.smartAggregations[clickedOrder].key) {
        index = i;
        return true;
      }
    });

    if (smart.key != 'post_time') {
      if (filter.selected) {
        filter.selected = false;
        if (
          variables.searchCallVariables.aggregations[index] &&
          variables.searchCallVariables.aggregations[index].type == filter.parent &&
          variables.searchCallVariables.aggregations[index].filter.length > 1
        ) {
          let moreFilters = variables.searchCallVariables.aggregations[index].filter.filter(
            (remove) => remove != filter.Contentname
          );
          variables.searchCallVariables.aggregations[index].filter = moreFilters;
        } else {
          let singlefilters = variables.searchCallVariables.aggregations.filter(
            (remove) => remove.type != filter.parent
          );
          variables.searchCallVariables.aggregations = singlefilters;
        }
      } else {
        filter.selected = true;
        if (
          variables.searchCallVariables.aggregations[index] &&
          variables.searchCallVariables.aggregations[index].type == filter.parent &&
          !variables.searchCallVariables.aggregations[index].filter.includes(filter.Contentname)
        ) {
          variables.searchCallVariables.aggregations[index].filter.push(filter.Contentname);
        } else {
          variables.searchCallVariables.aggregations.push({
            type: filter.parent,
            filter: [filter.Contentname]
          });
        }
      }
    } else if (
      (variables.searchCallVariables.aggregations[index].type == 'post_time' ||
        variables.searchCallVariables.aggregations[index].type == 'CreatedDate') &&
      !filter.selected
    ) {
      filter.selected = true;
      smart.values.forEach((cdate) => {
        if (cdate.Contentname != filter.Contentname) {
          cdate.selected = false;
        }
      });
      variables.searchCallVariables.aggregations[index].filter = [];
      variables.searchCallVariables.aggregations[index].filter.push(filter.Contentname);
    }

    setCookies.setSmartFacetOff();

    // In order to update the render
    setUpdate(!update);
  };

  return (
    <>
      {autocompleteResult &&
        autocompleteResult.smartAggregations &&
        autocompleteResult.smartAggregations.length != 0 &&
        autocompleteResult.smartAggregations.map((item, index) => (
          <div key={item.key}>
            <>
              {item.key && item.values.length != 0 && (
                <div data-key={index} className="su__product-suggestion">
                  <div className="su__product-sugg-iner su__flex-vcenter su__flex-wrap">
                    <div className="su__product-sugg-title su__mb-3 su__f-bold su__font-13">
                      {item.label}
                    </div>
                  </div>
                  <div className="su__d-flex">
                    <svg
                      className="su__product-sugg-icon"
                      width="25"
                      height="21.948"
                      viewBox="0 0 29.935 23.948"
                    >
                      <path
                        className="su__fill-red"
                        d="M5.987,16.464H1.5a1.5,1.5,0,0,0-1.5,1.5v4.49a1.5,1.5,0,0,0,1.5,1.5h4.49a1.5,1.5,0,0,0,1.5-1.5v-4.49A1.5,1.5,0,0,0,5.987,16.464ZM4.864,12.722h8.98v2.245H16.09V12.722h8.98v2.245h2.245V12.274a1.8,1.8,0,0,0-1.8-1.8H16.09V7.484h1.871a1.5,1.5,0,0,0,1.5-1.5V1.5a1.5,1.5,0,0,0-1.5-1.5H11.974a1.5,1.5,0,0,0-1.5,1.5v4.49a1.5,1.5,0,0,0,1.5,1.5h1.871v2.993H4.416a1.8,1.8,0,0,0-1.8,1.8v2.694H4.864Zm12.348,3.742h-4.49a1.5,1.5,0,0,0-1.5,1.5v4.49a1.5,1.5,0,0,0,1.5,1.5h4.49a1.5,1.5,0,0,0,1.5-1.5v-4.49A1.5,1.5,0,0,0,17.212,16.464Zm11.226,0h-4.49a1.5,1.5,0,0,0-1.5,1.5v4.49a1.5,1.5,0,0,0,1.5,1.5h4.49a1.5,1.5,0,0,0,1.5-1.5v-4.49A1.5,1.5,0,0,0,28.438,16.464Z"
                      />
                    </svg>
                    <div className="customProductSpacing su__flex-vcenter su__flex-wrap su__ml-3">
                      {item.key &&
                        item.values.length != 0 &&
                        item.values.slice(0, 3).map((product, index) => {
                          return (
                            <div
                              key={index}
                              className={
                                product.selected
                                  ? 'su__product-sugg-category su__cursor su__product-active su__font-13'
                                  : 'su__product-sugg-category su__font-13 su__cursor'
                              }
                              onClick={() => {
                                smartAutocompleteSelection(item, product);
                              }}
                            >
                              {product.Contentname}
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              )}
            </>
          </div>
        ))}
    </>
  );
};

export default AutoLearningSuggestion;
