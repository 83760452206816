import variables from './../redux/variables';

const searchtrack = (searchResult) => {

  const getPayloadWithWildcard = () => {
    let payload;
    var wildCardSearch = !!sessionStorage.getItem('toggleWildcardSearch');
    if(wildCardSearch && variables.searchCallVariables.searchString.charAt(0) !== '#'){ // if session storage is true
      payload = '#' + variables.searchCallVariables.searchString;
    }else{
      payload = variables.searchCallVariables.searchString;
    }
    return payload;
  };
  
  try{
    var filterAggregations = variables.searchCallVariables.aggregations;
    if(filterAggregations.length) {
      var filterAggregations = filterAggregations.filter(function(filter, index) {
          if(filter.filter) {
              return filterAggregations[index];
          } else {
              return false;
          }
      })
    }
    if( ['clear-advance-search','instantSearch','autocomplete','content-tile','no-result','facet-sorting'].includes(variables.searchSource)){ null }
    else if ((variables.searchCallVariables.searchString.trim() !== '' || variables.searchCallVariables.exactPhrase.trim() !== '') && !variables.searchCallVariables.suCaseCreate) {
      variables.searchAnalyticsObject = null;
      gza('search', {
        searchString: variables.searchCallVariables.searchString ? getPayloadWithWildcard() : variables.searchCallVariables.exactPhrase,
        result_count: searchResult.result.total,
        page_no: variables.searchCallVariables.pageNo,
        uid: variables.searchCallVariables.uid,
        isFreshSearch:['searchbox', 'advance-search','listBookmark','similar-search','recent-search','did-you-mean'].includes(variables.searchSource)? true: false,
        filter: filterAggregations,
        exactPhrase: variables.searchCallVariables.exactPhrase,
        withOneOrMore: variables.searchCallVariables.withOneOrMore,
        withoutTheWords: variables.searchCallVariables.withoutTheWords,
        dym: variables.dymClicked ? variables.previousDymSearchString : undefined,
        responseTime: variables.searchResposeTimer 
      });
      variables.isFreshSearch = false;
    } else if(['searchbox'].includes(variables.searchSource)) {
      variables.searchAnalyticsObject = {
        searchString: searchResult.searchQuery,
        result_count: searchResult.result.hits.length,
        page_no: variables.searchCallVariables.pageNo,
        uid: variables.searchCallVariables.uid,
        filter: filterAggregations,
        exactPhrase: variables.searchCallVariables.exactPhrase,
        withOneOrMore: variables.searchCallVariables.withOneOrMore,
        withoutTheWords: variables.searchCallVariables.withoutTheWords,
        dym: variables.dymClicked ? variables.previousDymSearchString : undefined
      };
    }
  } catch (e) {
    console.log('Error in searchtrack', e)
  }
};


export default searchtrack;