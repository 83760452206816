import { tabIndexes } from 'constants/a11y';
import React from 'react';
import { Fragment } from 'react';

const AutoCompleteResultTitle = (props) => {
    try {
        let { item, index, resultOpenNewTab } = props;
        return (
          <Fragment>
            <div className='autosuggestion_third'>
                <a tabIndex={tabIndexes.tabIndex_minus_1} className="su__text-decoration su__text-black su__text-truncate su__font-14 su__font-bold" href={item.href} target="_blank" rel="noopener noreferrer" onClick={() => resultOpenNewTab(item, index)}>
                    <span className="su__suggestion-title su__font-13 su__text-black su__f-regular" name={item.highlight.TitleToDisplay[0] || item.href} dangerouslySetInnerHTML={{ __html: item.highlight.TitleToDisplay[0] || item.href.replace(/ /g, '\u00a0') }} ></span>
                </a>
                </div>
            </Fragment>
        );
    } catch (e) {
        console.log('Error in AutoCompleteResultTitle component', e);
        return (<div className="autosuggestion_third"></div>);
    }
};

export default React.memo(AutoCompleteResultTitle);