import React from 'react';
import Icons from '../../../assets/svg-icon/svg';
import IconColors from '../../../IconColors';
const AutoCompleteSearchIcon = (props) => {
    try {
        let { redirection } = props;
        return (
            <button type="button" className="su__btn su__search_btn su__animate-zoom su__flex-vcenter su__position-absolute su__zindex su__bg-transparent su__rtlleft" onClick={(e) =>{console.log(e);redirection()}}>
                <Icons IconName="Search" width="24" height="24" color={IconColors.AutoCompleteSearchIcon} />
            </button>

        );
    } catch (e) {
        console.log('Error in Search Icon component', e);
        return (<div></div>);
    }
};

export default React.memo(AutoCompleteSearchIcon);