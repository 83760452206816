import { useState, useEffect, useRef } from 'react';
import utilityMethods from '../redux/utilities/utility-methods';
import variables from '../redux/variables';

/**
 * 
 * @param {Autocomplete visibility} initialIsVisible 
 */
const useComponentVisible = (initialIsVisible) => {
  const [isComponentVisible, setIsComponentVisible] = useState(
    initialIsVisible
  );
  const ref = useRef('');
  const handleHideDropdown = (e) => {
    if(e.key === 'Escape' ){
      setIsComponentVisible(false) 
      if( variables.autocompleteCallVariables.smartFacetsClicked){
        variables.searchCallVariables.aggregations = variables.searchCallVariables.clonedAggregations;
        utilityMethods.updateURLParams();
        variables.autocompleteCallVariables.smartFacetsClicked = false;
        variables.searchCallVariables.clonedAggregations = [];
      }
    } 
     
    if(ref.current && !ref.current.contains(e.target)){
      setIsComponentVisible(false)
      if( variables.autocompleteCallVariables.smartFacetsClicked){
        variables.searchCallVariables.aggregations = variables.searchCallVariables.clonedAggregations;
        utilityMethods.updateURLParams();
        variables.autocompleteCallVariables.smartFacetsClicked = false;
        variables.searchCallVariables.clonedAggregations = [];
      }
    } else setIsComponentVisible(true);
  };
  const handleScroll = () => {
    const top = window.pageYOffset || document.documentElement.scrollTop;
    if((top > 100) && !variables.keepAutoCompleteResultOpenOnScroll){
      setIsComponentVisible(false)
      if( variables.autocompleteCallVariables.smartFacetsClicked){
        variables.searchCallVariables.aggregations = variables.searchCallVariables.clonedAggregations;
        utilityMethods.updateURLParams();
        variables.autocompleteCallVariables.smartFacetsClicked = false;
        variables.searchCallVariables.clonedAggregations = [];
      }
    }
    
  };
  const handleClickOutside = (e) => {
    if(ref.current && !ref.current.contains(e.target)){
      setIsComponentVisible(false)
      if( variables.autocompleteCallVariables.smartFacetsClicked){
        variables.searchCallVariables.aggregations = variables.searchCallVariables.clonedAggregations;
        utilityMethods.updateURLParams();
        variables.autocompleteCallVariables.smartFacetsClicked = false;
        variables.searchCallVariables.clonedAggregations = [];
      }
    }
  };
  const handleonChange = (e) => {
    (ref.current && !ref.current.contains(e.target)) ? (
      setIsComponentVisible(false)
    ) :
      (setIsComponentVisible(true));
  };
  
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    document.addEventListener('keydown', handleHideDropdown);
    document.addEventListener('click', handleClickOutside);
    document.addEventListener('change', handleonChange);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      document.removeEventListener('keydown', handleHideDropdown);
      document.removeEventListener('click', handleClickOutside);
      document.removeEventListener('change', handleonChange);
    };
  });
  
  return { ref, isComponentVisible, setIsComponentVisible };
};
  

export default useComponentVisible;