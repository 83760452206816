import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/takeUntil';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/throttleTime';
import 'rxjs/add/operator/merge';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/from';
import 'rxjs/add/observable/timer';
import variables from './variables';
import { autocomplete } from './ducks';
import utilityMethods from './utilities/utility-methods';
import { mergeMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { searchUrlReq }  from './searchClientTypes';

/**
 * Make search call to backend on the basis of search client type.
 * @param {} queryPassed
 */
const autocompleteEpic = (action$) =>
  action$.pipe(
    ofType(autocomplete.start().type),
    mergeMap(async ({ queryPassed }) => {
      if(queryPassed.searchString == "") {
        return autocomplete.success({})
      }
      variables.controllingVariables.processing = true;
      let start = new Date();
      var paramsUrlReq = searchUrlReq(variables.searchClientType, queryPassed);
      try {
        /** Bypass standard search url with userDefined */
        if (variables.userDefinedAutoCompleteSearchUrl.url){
          var paramsUrlReq = variables.userDefinedAutoCompleteSearchUrl;
        }
        const response = await fetch(paramsUrlReq.url, paramsUrlReq.req);
        variables.controllingVariables.processing = false;
        if (!response.ok) {
          throw Error(response.statusText);
        }
        const resp = response;
        const results = await resp.json();
        variables.searchResposeTimer = new Date() - start;
        return autocomplete.success(results);
      }
      catch (err) {
        return autocomplete.fail(err);
      }
    })
  );

export default autocompleteEpic;
