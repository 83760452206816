/* eslint-disable no-inner-declarations */
/* eslint-disable no-undef */
import variables from './variables';
import utilityMethods from './utilities/utility-methods';

/**
 * search call and recomendations to backend on the basis of search client type.
 * @param {} queryPassed
 */

/** Refs */
const memoryRefs = [];

const getEmail = () => {
  return new Promise((resolve)=>{
    window.onVanillaReady(function (vanilla) {
      const { email = '' } = vanilla?.getCurrentUser();
      GzAnalytics.setUser(email);
      if(email){
        variables.searchCallVariables['email'] = window.su_utm;
        variables.autocompleteCallVariables['email'] = window.su_utm;
        resolve(email);
      }
    });
  });
};

async function setEmail() {
  if (variables.searchClientType == 21) {
    var getLoggedInUser = new GlideAjax('x_312362_c_a_su.getLoggedInUser');
    getLoggedInUser.addParam('sysparm_name', 'getUserEmail');
    getLoggedInUser.getXML(callback);

    function callback(result) {
      var data = result.responseXML.documentElement.getAttribute('answer');
      suGlobals.loggedInUser = data;
      if (data) {
        GzAnalytics.setUser(data);
        variables.searchCallVariables['email'] = window.su_utm;
        variables.autocompleteCallVariables['email'] = window.su_utm;
      }
    }
  } else if (variables.searchClientType == 11) {
    setTimeout(function() {
      if(HelpCenter) {
        let zendeskGuide = HelpCenter && HelpCenter.user && HelpCenter.user.email;
        if(zendeskGuide != ''){
          GzAnalytics.setUser(zendeskGuide);
          variables.searchCallVariables['email'] = window.su_utm;
          variables.autocompleteCallVariables['email'] = window.su_utm;
        } else {
          GzAnalytics.setUser('');
        }
      }
    }, 2000);
  } else if (variables.searchClientType == 12 || variables.searchClientType == 16){
    client.get('currentUser').then(function (user) {
      let agentEmail = user.currentUser.email;
      if (agentEmail != '') {
        GzAnalytics.setUser(user.currentUser.email);
        variables.searchCallVariables['email'] = agentEmail;
        variables.autocompleteCallVariables['email'] = agentEmail;
      } else {
        GzAnalytics.setUser('');
      }
    });
  }
  /** vanilla platform */
  else if (variables.searchClientType == 28) {
    utilityMethods.clearIntervalsAndTimeouts(memoryRefs); // cleanup
    if (variables.searchClientType == 28 && gdn?.meta?.roleToken != '') {
      const fetchEmail = await getEmail();
      if (fetchEmail) {
        memoryRefs.push(utilityMethods.withInterval(getEmail, 60 * 100)); // trigger interval
        utilityMethods.clearIntervalsAndTimeouts(memoryRefs); // cleanup
      }
    } else {
      GzAnalytics.setUser('');
    }
  }
  else {
    let emailInterval = setInterval(function() {
      if (window.user) {
        if(window.isGuestUser) window.user = '';
        clearInterval(emailInterval);
        GzAnalytics.setUser(window.user);
        variables.searchCallVariables['email'] = window.su_utm;
        variables.autocompleteCallVariables['email'] = window.su_utm;
      }
    }, 509);
  }
}

setEmail();

/** Get Higher logic access token */
function higherlogicAuth() {
  /**route need to changed as per client */
  var xhr = new XMLHttpRequest();
  xhr.open('GET', scConfiguration.jwt_href, false);
  xhr.onreadystatechange = function () {
    var tokenHigher = decodeURIComponent(xhr.responseURL).split('authtoken=')[1];
    localStorage.setItem('authtoken', tokenHigher);
  };
  xhr.send();
}
if (variables.searchClientType == 23) {
  higherlogicAuth();
  setInterval(function () { higherlogicAuth(); }, parseInt(scConfiguration.jwt_expiry) || 300000);
}

function aemkey() {
  window.email = JSON.parse(localStorage.getItem('ContextHubPersistence')).store.profile.email;
  var xhr = new XMLHttpRequest();
  /** Route need to be updated as per Client */
  xhr.open('GET', scConfiguration.jwt_href, false);
  xhr.onreadystatechange = function () {
    localStorage.setItem('authtoken', xhr.response);
  };
  xhr.send();
}
if (variables.searchClientType == 24) {
  aemkey();
  setInterval(function () { aemkey(); }, parseInt(scConfiguration.jwt_expiry) || 300000);
}

const getPayloadWithWildcard = (queryPassed) => {
  const payload = JSON.parse(JSON.stringify(queryPassed));
  var wildCardSearch = !!sessionStorage.getItem('toggleWildcardSearch');
  if(wildCardSearch && queryPassed.searchString.charAt(0) !== '#'){ // if session storage is true
    payload.searchString = '#' + queryPassed.searchString;
  }
  return JSON.stringify(payload);
};

export function searchUrlReq(searchClientType, queryPassed) {
  var instanceName = variables.searchClientProps.instanceName;
  var url = '';
  var req = {};
  if (searchClientType == 6 || searchClientType == 11 || searchClientType == 12 || searchClientType == 16 || searchClientType == 19 || searchClientType == 25 || searchClientType == 26 || searchClientType == 28) {
    if(searchClientType == 16 ){
      queryPassed.searchString = queryPassed.searchString=='' ? window.caseSubject : queryPassed.searchString;
    }
    var searchEndpoint = '/search/searchResultByPost';
    url = `${instanceName}${searchEndpoint}`;
    req = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: getPayloadWithWildcard(queryPassed)
    };
  } else if (searchClientType == 23) {
    queryPassed.authtoken = localStorage.getItem('authtoken');
    queryPassed.higherlogic = true;
    queryPassed.HLAuthToken = document.cookie.split('HLAuthToken=')[1].split(';')[0] || '';
    var searchEndpointHL = '/search/SUSearchResults';

    url = `${instanceName}${searchEndpointHL}`;
    req = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: getPayloadWithWildcard(queryPassed)
    };
  } else if (searchClientType == 24) {
    queryPassed.aem = true;
    var searchEndpointAEM = '/search/SUSearchResults';
    url = `${instanceName}${searchEndpointAEM}`;

    req = {
      method: 'POST',
      headers: {
        'authorization': 'bearer ' + localStorage.getItem('authtoken'),
        'Content-Type': 'application/json'
      },
      body: getPayloadWithWildcard(queryPassed)
    };
  } else if (searchClientType == 10 || searchClientType == 1) {
    // Jive
  }
  else if (searchClientType == 9) {
    var searchEndpointSFInternal = '/search/SUSearchResults';
    url = `${instanceName}${searchEndpointSFInternal}`;
    req = {
      method: 'POST',
      headers: {
        'authorization': 'bearer ' + jwtBearer,
        'Content-Type': 'application/json'
      },
      body: getPayloadWithWildcard(queryPassed)
    };
  } else if (searchClientType == 3) {
    var searchEndpointSFVisualForce = '/search/SUSearchResults';
    url = `${instanceName}${searchEndpointSFVisualForce}`;
    req = {
      method: 'POST',
      headers: {
        'authorization': 'bearer ' + jwtBearer,
        'Content-Type': 'application/json'
      },
      body: getPayloadWithWildcard(queryPassed)
    };
  } else if (searchClientType == 2) {
    let queryPassed = getPayloadWithWildcard(queryPassed);
    queryPassed = JSON.parse(queryPassed);
    queryPassed.aggregations = JSON.stringify(queryPassed.aggregations);
    if(queryPassed.pagingAggregation && typeof queryPassed.pagingAggregation != 'string') {
      queryPassed.pagingAggregation = JSON.stringify(queryPassed.pagingAggregation);
    }
    queryPassed.searchString = encodeURIComponent(queryPassed.searchString);
    const searchParams = Object.keys(queryPassed).map((key) => {
      return encodeURIComponent(key) + '=' + encodeURIComponent(queryPassed[key]);
    }).join('&');
    var getSearchResultsEndpoint = window.su_community_path;
    url = getSearchResultsEndpoint;
    req = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
      },
      body: searchParams
    };
    queryPassed.aggregations = JSON.parse(queryPassed.aggregations);
    queryPassed.searchString = decodeURIComponent(queryPassed.searchString);
  } else if (searchClientType == 14 || searchClientType == 15 || searchClientType == 17) {
    if (searchClientType == 14) {
      var BASEURL = document.getElementById('wpBaseUrl').value + '/wp-json/search-unify/v1/';
    } else if (searchClientType == 15) {
      BASEURL = window.location.origin + Drupal.settings.basePath + 'search-unify/v1/';
    } else if (searchClientType == 17) {
      BASEURL = window.location.origin + drupalSettings.path.baseUrl + 'search-unify/v1/';
    }
    var searchResultByPost = 'searchResultByPost';
    url = BASEURL + searchResultByPost;
    req = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: getPayloadWithWildcard(queryPassed)
    };
  } else if (searchClientType == 18) {
    var queryToBePassed = JSON.stringify(queryPassed);
    queryToBePassed = queryToBePassed.replace(/\"/g, '\'');
    var finalQueryPassed = { 'agre': queryToBePassed };
    var serverURL = location.protocol + '//' + location.host;
    var query = 'ser_Custom_Action';
    url = serverURL + '/api/data/v8.2/' + query;
    req = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: getPayloadWithWildcard(finalQueryPassed)
    };
  } else if (searchClientType == 21) {
    // serviceNow
  } else if (searchClientType == 20) {
    // url = scConfiguration.hosted_url+'/hostedSearchResults';
    req = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(queryPassed)
    };
  }
  return { url, req };
}

export function recomendationsUrlReq(searchClientType, data) {
  // http request for different searchClientType
  var instanceName = variables.searchClientProps.instanceName;
  var url = '';
  var req = {};
  var queryPassed = {
    'uid': data.uid,
    'searchString': variables.searchCallVariables.searchString,
    'sid': typeof _gr_utility_functions !== undefined ? _gr_utility_functions.getCookie('_gz_taid') : '',
    'language': localStorage.getItem('language') || 'en',
    'useremail': variables.searchCallVariables.email || ''
  };

  if (searchClientType == 1 || searchClientType == 6 || searchClientType == 10 || searchClientType == 11 || searchClientType == 12 || searchClientType == 19 || searchClientType == 25 || searchClientType == 26 || searchClientType == 28) {
    queryPassed['accessToken'] = data.accessToken;
  }
  if(data.isRecommendationsWidget){
    queryPassed['recommendationType'] = 2;
  }
  if (searchClientType == 2) {
    url = window.su_recommendations;
    const searchParams = Object.keys(queryPassed).map((key) => {
      return encodeURIComponent(key) + '=' + encodeURIComponent(queryPassed[key]);
    }).join('&');
    req = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
      },
      body: searchParams
    };
  } else if (searchClientType == 1 || searchClientType == 6 || searchClientType == 10 || searchClientType == 11 || searchClientType == 12 || searchClientType == 19 || searchClientType == 25 || searchClientType == 26 || searchClientType == 28) {
    url = instanceName + '/ai/getRecommendedResult';
    req = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(queryPassed)
    };
  } else if (searchClientType == 23) {
    queryPassed.authtoken = localStorage.getItem('authtoken');
    queryPassed.higherlogicRecomm = true;
    queryPassed.HLAuthToken = document.cookie.split('HLAuthToken=')[1].split(';')[0] || '';
    var searchEndpointHL = '/search/getRecommendedResult';

    url = `${instanceName}${searchEndpointHL}`;
    req = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(queryPassed)
    };
  } else if (searchClientType == 14 || searchClientType == 15 || searchClientType == 17) {
    if (searchClientType == 14) {
      var BASEURL = document.getElementById('wpBaseUrl').value + '/wp-json/search-unify/v1/';
    } else if (searchClientType == 15) {
      BASEURL = window.location.origin + Drupal.settings.basePath + 'search-unify/v1/';
    } else if (searchClientType == 17) {
      BASEURL = window.location.origin + drupalSettings.path.baseUrl + 'search-unify/v1/';
    }
    url = BASEURL + '/ai/getRecommendedResult';
    req = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(queryPassed)
    };
  } else if (searchClientType == 16) {
    url = instanceName + '/ai/getRecommendedResult';
    req = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(queryPassed)
    };
  } else if (searchClientType == 18) {
    url = instanceName + '/ai/getRecommendedResult';
    req = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(queryPassed)
    };
  } else if (searchClientType == 3) {
    var searchEndpointSFVisualForce = '/ai/authSURecommendation';
    url = `${instanceName}${searchEndpointSFVisualForce}`;
    req = {
      method: 'POST',
      headers: {
        'authorization': 'bearer ' + jwtBearer,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(queryPassed)
    };
  } else if (searchClientType == 9) {
    queryPassed.orgId = window.orgId;
    url = instanceName + '/ai/authSURecommendation';
    req = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'authorization': 'bearer ' + jwtBearer
      },
      body: JSON.stringify(queryPassed)
    };
  } else if (searchClientType == 24) {
    queryPassed.aem = true;
    url = instanceName + '/ai/authSURecommendation';
    req = {
      method: 'POST',
      headers: {
        'authorization': 'bearer ' + localStorage.getItem('authtoken'),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(queryPassed)
    };
  }

  if (searchClientType == 14 || searchClientType == 15) {
    url = BASEURL + 'getRecommendedResult';
  }

  return { url, req };
}

export default { searchUrlReq, recomendationsUrlReq };

