import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import AutocompleteApp from './App-autocomplete';
import configureStore from './redux/configureStore';
import 'isomorphic-fetch';
import '../src/assets/css/style.css';

const store = configureStore();

var suAutocompleteApp = setInterval(function () {
  if (document.querySelector('[bind-html-compile="autocompleteHtml"]')) {
    clearInterval(suAutocompleteApp);
    ReactDOM.render(
      <Provider store={store}>
        <AutocompleteApp />
      </Provider>,  document.querySelector('[bind-html-compile="autocompleteHtml"]')
    );
  }
},5);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
