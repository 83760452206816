/* eslint-disable no-self-assign */
import React from 'react';
import { Fragment } from 'react';

const AutoCompleteResultMetadata = (props) => {
  try {
    let { item } = props;
    return (
      <Fragment>
        {item.autosuggestData.filter((autosuggestItem) => autosuggestItem.key !== 'Title' && autosuggestItem.value && autosuggestItem.value.toString().length !==0 )
          .reverse().map((autosuggestItem, index) => {
            autosuggestItem.key === 'Description' && (
              autosuggestItem.value[0] = autosuggestItem.value[0]
            );
            return (
              <div key={index} className={`su__suggestion-desc su__align_flex su__line-height-n su__font-11 su__color-lgray su__suggest${autosuggestItem.key.split(' ').join('')}`}>
                <span className="su__color-gray su__f-bold">{autosuggestItem.key} : </span><span className="su__description-truncate" dangerouslySetInnerHTML={{ __html: autosuggestItem.value }}></span>
              </div>
            );
          }
          )}
      </Fragment>
    );
  } catch (e) {
    console.log('Error in AutoCompleteResultMetadata component', e);
    return (<div></div>);
  }
};

export default React.memo(AutoCompleteResultMetadata);